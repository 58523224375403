.row {
  display: flex;
  align-items: baseline;
}

.spacer {
  flex: 1 1 auto;
}

.center-vertical {
  align-items: center;
}

.stretch {
  flex: 1 1 auto;
}

.gap-1 {
  margin: 8px 8px;
}

.gap-2 {
  margin: 16px 16px;
}

.gap-4 {
  margin: 24px 24px;
}

.gap-4 {
  margin: 32px 32px;
}

.instruction-parent {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instruction-gif {
  max-height: 100%;
  max-width: 960px;
  object-fit: contain;
}
